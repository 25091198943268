<template>
  <v-container fill-height fluid class="pa-0" id="login">
    <v-row align="start" justify="center" class="fill-height" no-gutters>
      <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="11" sm="" md="7" lg="8" class="fill-height .d-none .d-sm-none .d-md-flex">
        <v-sheet color="primary" class="fill-height">
          <background v-if="whiteLabel == null" light style="width:100%"></background>
          <v-container fill-height fluid class="pa-0">
            <v-row align-self="center" justify="center" no-gutters>
              <v-col cols="12" sm="8" md="7" lg="4" class="fill-height text-center">
                <div v-if="appLogo == null">
                  <v-img src="@/assets/logo-CF.png" contain style="z-index:1"></v-img>
                </div>
                <div v-else>
                  <v-img :src="appLogo" contain style="z-index:1"></v-img>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="12" md="5" lg="4" class="fill-height" style="z-index:99">
        <v-sheet color="background" class="fill-height text-center" >
        <v-container fill-height fluid class="pa-0">
          <v-row align="center" justify="center" no-gutters>
            <v-col class="fill-height text-center" cols="10" sm="9" md="10">
              <login v-if="$route.name == 'Login'"></login>
              <signup v-else-if="$route.name == 'SignUp'"></signup>
              <freeTrial v-else-if="$route.name == 'FreeTrial'"></freeTrial>
            </v-col>
          </v-row>
        </v-container>
        </v-sheet>
      </v-col>
    </v-row>
    <v-dialog v-model="passDialog" persistent max-width="500">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('fPass2') }}<address></address></span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formRecover" v-model="validRecover" :lazy-validation="lazy">
            <p class="body-2 ma-0" >{{ $t('recoverMsg') }}</p>
            <v-row justify="center">
              <v-col class="py-0" cols="12">
                <v-text-field class="d-none" v-model="emailRecover" required></v-text-field>
                <v-text-field :label="$t('email')" v-model="emailRecover" required :rules="emailRules" @keydown.enter="sendRecovery()"></v-text-field>
              </v-col>
              <v-col class=" py-0 my-0">
                <p class="caption">This site is protected by reCAPTCHA and the Google
                  <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                  <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </p>
              </v-col>
              
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="passDialog = false">{{ $t('close') }}</v-btn>
          <v-btn color="blue darken-1" text :loading="sendingReEmail" :disabled="!validRecover || sendingReEmail" @click="sendRecovery()">{{ $t('sendRecover') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="" text v-bind="attrs" @click="snackbar = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import firebase from 'firebase'
var axios = require("axios")
import Background from '../components/background.vue';
import login from '@/components/auth/login'
import signup from '@/components/auth/signup'
import freeTrial from '@/components/auth/freeTrial'

export default {
  name: 'Login',
  components: {
    Background,
    signup,
    login,
    freeTrial
  },
  data: () => ({
    email: '',
    password: '',
    show1: false,
    passDialog: false,
    valid: true,
    lazy: false,
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    passwordRules: [
      v => !!v || 'Password is required',
      // v => /.*/.test(v) || 'Passo'
    ],
    sendingReEmail:false,
    validRecover: false,
    emailRecover: '',
    logging: false,
    // snackbar
    snackbar: false,
    snackbarText: '',
    snackbarTimeout: 5000,
    snackbarColor: '',
  }),
  computed: {
    whiteLabel() {
      return process.env.VUE_APP_WHITE_LABEL
    },
    appLogo() {
      return process.env.VUE_APP_DEFAULT_LOGO
    },
  },
  methods: {
    login () {
      let vm = this
      vm.logging = true
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(function () {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          firebase.auth().signInWithEmailAndPassword(vm.email, vm.password).then(function (user) {
            vm.$router.replace('/home')
            vm.logging = false
            return user
          // eslint-disable-next-line 
          }).catch((err) => {
            let msg = vm.$t('loginView.loginErr')
            if(err.code == 'auth/network-request-failed'){
              msg = vm.$t('loginView.networkFailed')
            } else if(err.code == 'auth/user-disabled') {
              msg = vm.$t('loginView.userDisabled')
            }
            else if(err.code == 'auth/auth/invalid-email') {
              msg = vm.$t('loginView.invalidEmail')
            }
            vm.logging = false
            vm.setSnackbar(msg, 'error')
            // vm.password=null
          })
        })
        // eslint-disable-next-line
        .catch(function (error) {
          vm.logging = false
        })
    },

    async sendRecovery() {
      if(!this.validRecover || this.sendingReEmail){
        return
      }
      let vm = this
      this.sendingReEmail = true
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('recoveryEmail')

      var obj = {
            email: this.emailRecover,
            recaptcha: token
          }

      var data = JSON.stringify(obj)
      var config = {
        method: 'POST',
        url: process.env.VUE_APP_ENGINE+'auth/reset',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      // eslint-disable-next-line
      axios(config).then(function (response) {
        vm.passDialog=false
        vm.sendingReEmail = false
        vm.emailRecover = ''
        vm.$refs.formRecover.resetValidation()
        vm.setSnackbar(vm.$t('loginView.recoverSuc'), "success")
        // eslint-disable-next-line
      }).catch(function (error) {
        vm.passDialog=false
        vm.sendingReEmail = false
        vm.emailRecover = ''
        vm.$refs.formRecover.resetValidation()
        vm.setSnackbar(vm.$t('loginView.recoverErr'), "error")
        return false
      })
    },
    logout () {
      firebase.auth().signOut().then(function () {
        // Sign-out successful.+
      // eslint-disable-next-line
      }).catch(function (error) {
        // An error  happened.
      })
    },
    setSnackbar (text, color, timeout = 5000) {
      this.snackbarText = text
      this.snackbarColor = color
      this.snackbarTimeout = timeout
      this.snackbar = true
    },
  },
  beforeMount() {
    //  this.logout()
    this.img = process.env.VUE_APP_DEFAULT_LOGO
    this.$vuetify.theme.dark = false
  },
  mounted() {
    this.$vuetify.goTo('#login')
  },
  watch: {
    passDialog: function () {
      if(this.passDialog){
        try{
          this.$refs.formRecover.reset()
        // eslint-disable-next-line
        } catch{}
        const recaptcha = this.$recaptchaInstance
        recaptcha.showBadge()
      }
      else{
        const recaptcha = this.$recaptchaInstance
        recaptcha.hideBadge()
      }
    }
  }
}
</script>
