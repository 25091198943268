<template>
  <div class="login" light>
    <div class="text-h4 text-center pb-8">{{ $t('login') }}</div>
    <div v-if="mfa == false">
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-text-field
          outlined
          :label="$t('email')"
          name="email"
          type="text"
          v-model="email"
          :placeholder="$t('emailExample')"
          persistent-placeholder
          :autofocus="'autofocus'"
          :rules="$t('rules.emailRules')"
        ></v-text-field>
        <v-text-field
          outlined
          id="password"
          :label="$t('password')"
          name="password"
          :type="show1 ? 'text' : 'password'"
          v-model="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="show1 = !show1"
          @keydown.enter="login"
          :placeholder="$t('enterPassword')"
          persistent-placeholder
          :rules="$t('loginView.passwordRules')"
          autocomplete="current-password"
        ></v-text-field>
        <a><p class="caption text-center" @click="passDialog = true">{{ $t('fPass2') }}</p></a>
      </v-form>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-btn id="loginBtn" :class="{ 'accent': !$vuetify.theme.dark }" block large :loading="logging" @click="login">{{ $t('login') }}</v-btn>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col class="py-0">
          <v-btn text @click="otherProviders=true">
            <v-icon>mdi-google</v-icon>
            <v-icon>mdi-microsoft</v-icon>
          </v-btn>
        </v-col>
      </v-row> -->
      <!-- <div :class="$route.query.showProviders=='true' ? '': ''">
        <div div id="firebaseui-auth-container2"></div>
        <div id="loader2">Loading...</div>
      </div> -->
      <v-row align-content="center" justify="center"> 
        <v-col cols="12">
          <div d-flex class="title text-center">{{ $t('or') }}</div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" class="pt-0">
          <v-btn text color="accent" block to="/signup">{{ $t('signUp') }}</v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div class="text-center" id="recaptcha-container-id"></div>
      <div v-if="!mfa2">
        <div class="text-p1 pb-3">{{ $t('loginView.MFAins', {phone: phoneNumber}) }}</div>
        <v-btn id="log-in-button" color="primary" @click="sendCode">{{ $t('loginView.sendCode') }}</v-btn>
      </div>
      <div v-else>
        <v-form v-model="validCode">
          <v-text-field class="d-none" filled @keydown.enter="loginMFA" v-model="verificationCode" :label="$t('enterCodeHere')" :rules="$t('rules.verifyCodeRules')"></v-text-field>
          <v-text-field filled @keydown.enter="loginMFA" v-model="verificationCode" :label="$t('enterCodeHere')" :rules="$t('rules.verifyCodeRules')"></v-text-field>
          <v-btn color="primary" :disabled="!validCode" @click="loginMFA">{{ $t('login') }}</v-btn>
        </v-form>
      </div>
    </div>
    <v-dialog v-model="passDialog" persistent max-width="500">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('fPass2') }}<address></address></span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formRecover" v-model="validRecover" :lazy-validation="lazy">
            <p class="body-2 ma-0" >{{ $t('recoverMsg') }}</p>
            <v-row justify="center">
              <v-col class="py-0" cols="12">
                <v-text-field class="d-none" v-model="emailRecover" required></v-text-field>
                <v-text-field :label="$t('email')" v-model="emailRecover" required :rules="emailRules" @keydown.enter="sendRecovery()"></v-text-field>
              </v-col>
              <v-col class=" py-0 my-0">
                <p class="caption">This site is protected by reCAPTCHA and the Google
                  <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                  <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </p>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="passDialog = false">{{ $t('close') }}</v-btn>
          <v-btn color="blue darken-1" text :loading="sendingReEmail" :disabled="!validRecover || sendingReEmail" @click="sendRecovery()">{{ $t('sendRecover') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="" text v-bind="attrs" @click="snackbar = false">
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="otherProviders" max-width="400">
      <v-card class="pa-3">
        <div div id="firebaseui-auth-container"></div>
        <div id="loader">Loading...</div>
      </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import {firebase, auth, firebaseui} from '@/services/firebase'
var axios = require("axios")


export default {
  name: 'login',
  data: () => ({
    email: '',
    password: '',
    show1: false,
    passDialog: false,
    valid: true,
    lazy: false,
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    passwordRules: [
      v => !!v || 'Password is required',
      // v => /.*/.test(v) || 'Passo'
    ],
    sendingReEmail:false,
    validRecover: false,
    emailRecover: '',
    logging: false,
    otherProviders:false,
    // mfa
    resolver: null,
    mfa: false,
    mfa2: false,
    verificationCode: null,
    validCode: false,
    recaptchaVerifier: null,
    phoneNumber: null,
    sendingCode: false,
    verificationId: null,
    // snackbar
    snackbar: false,
    snackbarText: '',
    snackbarTimeout: 5000,
    snackbarColor: '',
  }),
  methods: {
    sendCode (){
      let vm = this
      vm.sendingCode = true
      var phoneInfoOptions = {
        multiFactorHint: vm.resolver.hints[0],
        session: vm.resolver.session
      }
      var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, vm.recaptchaVerifier)
        .then(function(verificationId) {
          // verificationId will be needed for sign-in completion.
          vm.verificationId = verificationId
          vm.sendingCode = false
          vm.mfa2 = true
      }).catch(function(error) {
        console.log(error)
        vm.sendingCode = false
        grecaptcha.reset(window.recaptchaWidgetId)
        vm.mfa2 = false
        vm.sendingCode = false
      })
    },
    loginMFA () {
      let vm = this
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(function () {
        vm.logging2 = true
        var cred = firebase.auth.PhoneAuthProvider.credential(vm.verificationId, vm.verificationCode)
        var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred)
        vm.resolver.resolveSignIn(multiFactorAssertion)
        .then(function(userCredential) {
          // userCredential will also contain the user, additionalUserInfo, optional
          // credential (null for email/password) associated with the first factor sign-in.
          // For example, if the user signed in with Google as a first factor,
          // userCredential.additionalUserInfo will contain data related to Google provider that
          // the user signed in with.
          // user.credential contains the Google OAuth credential.
          // user.credential.accessToken contains the Google OAuth access token.
          // user.credential.idToken contains the Google OAuth ID token.
          vm.$router.replace('/home')
          vm.logging = false
          vm.logging2 = false
          return userCredential
        }).catch(function(error){
          vm.logging = false
          vm.logging2 = false
          vm.setSnackbar('Please try again later. Contact support.')
        })
      })
    
    },
    onSolvedRecaptcha(){
      vm.mfa2 = true
    },
    login () {
      if(!this.valid){
        let msg = this.$t('loginView.loginErr')
        this.setSnackbar(msg, 'error')
        return
      }
      let vm = this
      vm.logging = true
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(function () {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          firebase.auth().signInWithEmailAndPassword(vm.email, vm.password).then(function (user) {
            vm.$router.replace('/home')
            vm.logging = false
            return user
          // eslint-disable-next-line 
          }).catch((err) => {
            let msg = vm.$t('loginView.loginErr')
            if(err.code == 'auth/network-request-failed'){
              msg = vm.$t('loginView.networkFailed')
            } else if(err.code == 'auth/user-disabled') {
              msg = vm.$t('loginView.userDisabled')
            }
            else if(err.code == 'auth/auth/invalid-email') {
              msg = vm.$t('loginView.invalidEmail')
            }
            else if(err.code == 'auth/multi-factor-auth-required'){
              vm.mfa = true
              vm.resolver = err.resolver
              setTimeout(() => { 
                vm.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('log-in-button', {
                  'size': 'invisible',
                  'callback': function(response) {
                    // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
                    vm.onSolvedRecaptcha()
                  },
                  'expired-callback': function() {
                    // Response expired. Ask user to solve reCAPTCHA again.
                    // ...
                    vm.recaptchaVerifier.render()
                    .then(function(widgetId) {
                      grecaptcha.reset(widgetId);
                    })
                  }
                })
                vm.recaptchaVerifier.render()
                .then(function(widgetId) {
                  window.recaptchaWidgetId = widgetId;
                })
              }, 500)
              if (vm.resolver.hints[0].factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
                vm.phoneNumber = vm.resolver.hints[0].phoneNumber
              } else {
                vm.setSnackbar('Invalid MFA, contact support', 'error')
              }
              return
            }
            vm.logging = false
            vm.setSnackbar(msg, 'error')
            // vm.password=null
          })
        })
        // eslint-disable-next-line
        .catch(function (error) {
          vm.logging = false
        })
    },

    async sendRecovery() {
      if(!this.validRecover || this.sendingReEmail){
        return
      }
      let vm = this
      this.sendingReEmail = true
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('recoveryEmail')

      var obj = {
            email: this.emailRecover,
            recaptcha: token
          }

      var data = JSON.stringify(obj)
      var config = {
        method: 'POST',
        url: process.env.VUE_APP_ENGINE+'auth/reset',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      // eslint-disable-next-line
      axios(config).then(function (response) {
        vm.passDialog=false
        vm.sendingReEmail = false
        vm.emailRecover = ''
        vm.$refs.formRecover.resetValidation()
        vm.setSnackbar(vm.$t('loginView.recoverSuc'), "success")
        // eslint-disable-next-line
      }).catch(function (error) {
        vm.passDialog=false
        vm.sendingReEmail = false
        vm.emailRecover = ''
        vm.$refs.formRecover.resetValidation()
        vm.setSnackbar(vm.$t('loginView.recoverErr'), "error")
        return false
      })
    },
    logout () {
      firebase.auth().signOut().then(function () {
        // Sign-out successful.+
      // eslint-disable-next-line
      }).catch(function (error) {
        // An error  happened.
      })
    },
    setSnackbar (text, color, timeout = 5000) {
      this.snackbarText = text
      this.snackbarColor = color
      this.snackbarTimeout = timeout
      this.snackbar = true
    },
    startFirebaseUI(start=false){
      let vm = this
      if(firebaseui.auth.AuthUI.getInstance()) {
        this.ui = firebaseui.auth.AuthUI.getInstance()
      }
      else {
        this.ui = new firebaseui.auth.AuthUI(auth);
      }
      var uiConfig = {
        signInFlow: 'popup',
        callbacks: {
          signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            console.log(authResult)
            console.log(redirectUrl)
            vm.$router.replace('/home')
            vm.logging = false
            return false;
          },
          uiShown: function() {
            // The widget is rendered.
            // Hide the loader.
            try {
              document.getElementById('loader').style.display = 'none';
            }
            catch {

            }
            try {
              document.getElementById('loader2').style.display = 'none';
            }
            catch {
              
            }
            
          }
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        // signInFlow: 'popup',
        // signInSuccessUrl: window.location.href.slice(0, window.location.href.length - 6)+'home',
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            providerName: 'Google',
            // To override the full label of the button.
            fullLabel: 'Login with Google',
            // buttonColor: '#2F2F2F',
            loginHintKey: 'login_hint',
            // scopes: [
            //   'mail.read'
            // ],
            customParameters: {
              prompt: 'consent'
            }
          },
          {
            provider: 'microsoft.com',
            providerName: 'Microsoft',
            // To override the full label of the button.
            fullLabel: 'Login with Microsoft',
            buttonColor: '#2F2F2F',
            loginHintKey: 'login_hint',
            // scopes: [
            //   'mail.read'
            // ],
            customParameters: {
              prompt: 'consent'
            }
          }
        ],
        // Terms of service url.
        tosUrl: 'https://contractflows.com/terms',
        // Privacy policy url.
        privacyPolicyUrl: 'https://contractflows.com/privacy'
      };
      if(start){
        this.ui.start('#firebaseui-auth-container2', uiConfig)
      }
      else{
        this.ui.start('#firebaseui-auth-container', uiConfig)
      }
      this.ui.disableAutoSignIn()
    }
  },
  beforeMount() {
    //  this.logout()
  },
  mounted() {
    // this.startFirebaseUI(true)
  },
  watch: {
    passDialog: function () {
      if(this.passDialog){
        try{
          this.$refs.formRecover.reset()
        // eslint-disable-next-line
        } catch{}
        const recaptcha = this.$recaptchaInstance
        recaptcha.showBadge()
      }
      else{
        const recaptcha = this.$recaptchaInstance
        recaptcha.hideBadge()
      }
    },
    otherProviders: function() {
      if(this.otherProviders){
        // setTimeout(() => {  this.startFirebaseUI() }, 1000);
      }
    }
  }
}
</script>

<style>

</style>
