<template>
  <HomeDash/>
</template>

<script>
import HomeDash from '../components/HomeDash'

export default {
  components: {
    HomeDash,
  },
  mounted() {
    if(localStorage.customTheme != null) {
      const newTheme = JSON.parse(localStorage.customTheme)
      for(var key in newTheme){
        this.$vuetify.theme.themes['light'][key] = newTheme[key]
      }
    }
    // theme
    if(localStorage.dark != null){
      if(localStorage.dark == 'true'){
        this.$vuetify.theme.dark = true
        this.dark = true
      }
      else{
        this.$vuetify.theme.dark = false
        this.dark = false
      }
    } else{
      this.$vuetify.theme.dark = false
      this.dark = false
    }
  }
};
</script>
