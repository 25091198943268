import firebase from 'firebase/app'
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/functions"

var firebaseui = require('firebaseui')

const config = {
  apiKey: process.env.VUE_APP_FIRECONFIG_APIKEY,
  authDomain: process.env.VUE_APP_FIRECONFIG_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIRECONFIG_DBURL,
  projectId: process.env.VUE_APP_FIRECONFIG_PROJECTID,
  storageBucket: process.env.VUE_APP_FIRECONFIG_STORAGE,
  messagingSenderId: process.env.VUE_APP_FIRECONFIG_MSGSENDERID,
  appId: process.env.VUE_APP_FIRECONFIG_APPID,
  measurementId: process.env.VUE_APP_FIRECONFIG_MEASUREMENTID
}
firebase.initializeApp(config)
try{
  firebase.analytics()
}
catch {
  // do nothing?
}

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
          unsubscribe()
          resolve(user)
      }, reject)
  })
}

const auth = firebase.auth()
const functions = firebase.functions()
if(process.env.NODE_ENV == 'development'){
  console.log('using local functions')
  functions.useEmulator("127.0.0.1", 5001)
}
const db = firebase.firestore()
const storage = firebase.storage()
export {
  firebase,
  auth,
  db,
  storage,
  functions,
  firebaseui
}
