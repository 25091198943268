import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/store/index'
import VueMeta from 'vue-meta'
import {firebase} from './services/firebase'
import Login from './views/Login'
import Home from './views/Home'

Vue.use(Router)
Vue.use(VueMeta)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/home',
      meta:{
        title: process.env.VUE_APP_TITLE
      }
    },
    {
      path: '/index.html',
      redirect: '/home',
      meta:{
        title: process.env.VUE_APP_TITLE
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta:{
        title: process.env.VUE_APP_TITLE+'-Login'
      }
    },
    {
      path: '/signup',
      name: 'SignUp',
      component: Login,
      meta:{
        title: process.env.VUE_APP_TITLE+'-SignUp'
      }
    },
    {
      path: '/free-trial',
      name: 'FreeTrial',
      component: Login,
      meta:{
        title: process.env.VUE_APP_TITLE+'-FreeTrial'
      }
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth: true,
        title: process.env.VUE_APP_TITLE+'-Home'
      }
    },
    {
      path: '/processes/:id?',
      name: 'Processes',
      component: () => import('./views/Processes'),
      meta: {
        requiresAuth: true,
        roles: ['owner', 'creator', 'consultant'],
        title: process.env.VUE_APP_TITLE+'-Processses'
      }
    },
    {
      path: '/managetemplates',
      name: 'ManageTemplates',
      component: () => import('./views/ManageTemplates'),
      meta: {
        requiresAuth: true,
        roles: ['owner', 'manager'],
        title: process.env.VUE_APP_TITLE+'-ManageTemplates'
      }
    },
    {
      path: '/manageorg',
      name: 'ManageOrg',
      component: () => import('./views/ManageOrg'),
      meta: {
        requiresAuth: true,
        noOrgAccess: true,
        roles: ['owner', 'manager'],
        title: process.env.VUE_APP_TITLE+'-ManageOrg'
      }
    },
    {
      path: '/myaccount',
      name: 'MyAccount',
      component: () => import('./views/MyAccount'),
      meta: {
        requiresAuth: true,
        title: process.env.VUE_APP_TITLE+'-MyAccount'
      }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import('./views/Dashboard'),
      meta: {
        requiresAuth: true,
        roles: ['owner', 'manager'],
        title: process.env.VUE_APP_TITLE+'-Dashboard'
      }
    },
    {
      path: '/templateSettings/:id',
      name: 'TemplateSettings',
      component: () => import('./views/TemplateSettings'),
      props: true,
      meta: {
        requiresAuth: true,
        roles: ['owner', 'manager'],
        title: process.env.VUE_APP_TITLE+'-TemplateSettings'
      }
    },
    {
      path: '/reviews/:id?',
      name: 'Reviews',
      component: () => import('./views/Reviews'),
      meta: {
        requiresAuth: true,
        roles: ['owner', 'reviewer'],
        title: process.env.VUE_APP_TITLE+'-Reviews'
      }
    },
    {
      path: '/editor',
      name: 'Editor',
      component: () => import('@/views/Editor.vue'),
      meta: {
        requiresAuth: true,
        title: process.env.VUE_APP_TITLE+'-Editor'
      }
    },
    // {
    //   path: '/templateCreator',
    //   name: 'TemplateCreator',
    //   component: TemplateCreator,
    //   meta: {
    //     requiresAuth: true,
    //     title: 'Contractflows-Reviews'
    //   }
    // },
    {
      path: '/payments',
      name: 'payments',
      component: () => import('./views/Payments'),
      meta: {
        white_label_disabled: true,
        requiresAuth: true,
        roles: ['owner'],
        title: process.env.VUE_APP_TITLE+'-Payments'
      }
    },
    {
      path: '/thank_you',
      name: 'ThankYou',
      component: () => import('./views/ThankYou'),
      meta: {
        requiresAuth: false,
        title: process.env.VUE_APP_TITLE+'-ThankYou!'
      }
    },
    {
      path: '/thank_you_recurrente',
      name: 'ThankYouR',
      component: () => import('./views/ThankYou'),
      meta: {
        requiresAuth: false,
        title: process.env.VUE_APP_TITLE+'-ThankYou!'
      }
    },
    {
      path: '/thankYouSignUp',
      name: 'ThankYouSignUp',
      component: () => import('./views/ThankYouSignUp'),
      meta: {
        requiresAuth: false,
        title: process.env.VUE_APP_TITLE+'-ThankYou!'
      }
    },
    {
      path: '/auth/action',
      name: 'Auth',
      component: () => import('@/views/Auth.vue')
    },
    {
      path: '/ds/callback',
      name: 'DS',
      component: () => import('@/views/DocuSignCallbackAuth.vue'),
      meta: {
        requiresAuth: true,
        title: process.env.VUE_APP_TITLE+'-DocusignAuth'
      }
    },
    {
      path: '/alerts',
      name: 'Alerts',
      component: () => import('@/views/alerts/Alerts.vue'),
      meta: {
        requiresAuth: true,
        title: process.env.VUE_APP_TITLE+'-Alerts'
      },
    },
    {
      path: '/alerts/:id',
      name: 'AlertDetail',
      component: () => import('@/views/alerts/AlertDetail.vue'),
      meta: {
        requiresAuth: true,
        title: process.env.VUE_APP_TITLE+'-Alert detail'
      }
    },
    {
      path: '/utilities',
      name: 'Utilities',
      component: () => import('@/views/Utilities.vue'),
      meta: {
        requiresAuth: true,
        title: process.env.VUE_APP_TITLE+'-Utilities'
      }
    },
    {
      path: '/reports',
      name: 'Reports',
      component: () => import('@/views/Reports.vue'),
      meta: {
        requiresAuth: true,
        title: process.env.VUE_APP_TITLE+'-Reports'
      }
    },
    {
      path: '/hp',
      name: 'HP',
      component: () => import('@/views/horizontalProperty/HorizontalProperty.vue'),
      meta: {
        requiresAuth: true,
        title: process.env.VUE_APP_TITLE+'-HP'
      }
    },
    {
      path: '/hp/projects',
      name: 'HP - Projects',
      component: () => import('@/views/horizontalProperty/Projects.vue'),
      meta: {
        requiresAuth: true,
        title: process.env.VUE_APP_TITLE+'-HP-Projects'
      }
    },
    {
      path: '/hp/projects/:id',
      name: 'HP-ProjectDetail',
      component: () => import('@/views/horizontalProperty/Project.vue'),
      meta: {
        requiresAuth: true,
        title: process.env.VUE_APP_TITLE+'-Project detail'
      }
    },
    {
      path: '/hp/projects/:id/settings',
      name: 'HP-ProjectSettings',
      component: () => import('@/views/horizontalProperty/project/Settings.vue'),
      meta: {
        requiresAuth: true,
        title: process.env.VUE_APP_TITLE+'-Project settings'
      }
    },
    {
      path: '/hp/projects/:id/settings/levels/:levelId',
      name: 'HP-Levels',
      component: () => import('@/views/horizontalProperty/project/Level.vue'),
      meta: {
        requiresAuth: true,
        title: process.env.VUE_APP_TITLE+'-Project settings'
      }
    },
    {
      path: '/org/project/:id',
      name: 'ProjectSettings',
      component: () => import('./views/ProjectSettings'),
      props: true,
      meta: {
        requiresAuth: true,
        roles: ['owner'],
        title: process.env.VUE_APP_TITLE+'-Project settings'
      }
    },
    {
      path: '/shared',
      name: 'Shared',
      component: () => import('./views/Shared'),
      meta: {
        requiresAuth: true,
        title: process.env.VUE_APP_TITLE+'-Shared'
      }
    },
    {
      path: '/public/:id/:id2',
      name: 'Public',
      component: () => import('./views/public/Public'),
      meta: {
        requiresAuth: false,
        title: process.env.VUE_APP_TITLE+'-Public'
      }
    },
  ]
})

// router.beforeEach((to, from, next) => {
//   let currentUser = firebase.auth().currentUser
//   let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
//   const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
//   if(nearestWithTitle) document.title = nearestWithTitle.meta.title
//   // if (!currentUser) next('login')
//   if (requiresAuth && !currentUser) next('login')
//   else if (!requiresAuth && currentUser) next('home')
//   else next()
// })



router.beforeEach(async (to, from, next) => {
  try {
    Store.commit('setLastRoute', from.name)
    // eslint-disable-next-line
  } catch (err) {
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const whiteLabelDisabled = to.matched.some(record => record.meta.white_label_disabled)
  const roles = to.meta.roles
  const noOrgAccess = to.meta.noOrgAccess
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title
  if(process.env.VUE_APP_WHITE_LABEL != null && whiteLabelDisabled){
    next('home')
  }
  if (requiresAuth && !await firebase.getCurrentUser()){
    next('login');
  }else{
    if(roles == null){
      next()
    }
    else if(Store.getters.ROLES == null){
      if(noOrgAccess == true){
        next()
      } else {
        next('home')
      }
    }
    else if(Store.getters.ROLES.some(r=> roles.includes(r))){
      // console.log('going next')
      // console.log(from)
      // console.log(to)
      next()
    } else {
      next('home');
    }
  }
})

export default router
