<template>
  <div class="dashButton text-left" color="primary">
    <v-card hover @click="goTo(route)" height="100%" :id="route+'Btn'">
      <v-card-title class="text-h5 font-weight-medium" :class="{'primary--text': !$vuetify.theme.dark}">
        <v-row no-gutters align="center">
          <v-col>
            {{ title }}
          </v-col>
          <v-col cols="auto">
            <v-icon large color="accent">{{ icon }}</v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters align="center">
          <v-col class="text-subtitle-1 font-weight-regular" >
            {{ description }}
          </v-col>
          <v-col cols="auto" v-if="upgradeAvailable">
            <v-tooltip bottom max-width="200" class="text-center">
              <template v-slot:activator="{ on, attrs }">
                <v-chip dark small color="green" v-bind="attrs" v-on="on" @click="$router.push('payments')">{{ $t('upgrade') }}</v-chip>
              </template>
              <span class="text-center">{{ $t('upgradeSubPlan') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        
        <div class="text-right">
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="(status == false && limited) || upgradeAvailable" text disabled>{{ $t('disabled') }}</v-btn>
        <v-btn v-else text disabled></v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    title: {
      required: true
    },
    description: {
      required: true
    },
    route: {
      required: true
    },
    status: {
      required: true
    },
    limited: {
      required: true
    },
    roles: {
      required: true
    },
    upgradeAvailable: {
      required: false
    },
    icon: {
      required: false,
    }
  },
  data: () => ({
  }),
  computed: {
  },
  methods: {
    goTo(route){
      if((!this.status && this.limited) || this.upgradeAvailable) return
      this.$router.push(route)
    }
  }
}
</script>

<style>

</style>
